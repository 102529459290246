/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CredentialDeviceTypeFromJSON, CredentialDeviceTypeToJSON, } from './CredentialDeviceType';
/**
 * Check if a given object implements the Passkey interface.
 */
export function instanceOfPasskey(value) {
    if (!('credentialId' in value) || value['credentialId'] === undefined)
        return false;
    if (!('signCount' in value) || value['signCount'] === undefined)
        return false;
    if (!('transports' in value) || value['transports'] === undefined)
        return false;
    if (!('deviceType' in value) || value['deviceType'] === undefined)
        return false;
    if (!('backUp' in value) || value['backUp'] === undefined)
        return false;
    if (!('aaguid' in value) || value['aaguid'] === undefined)
        return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined)
        return false;
    return true;
}
export function PasskeyFromJSON(json) {
    return PasskeyFromJSONTyped(json, false);
}
export function PasskeyFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'credentialId': json['credential_id'],
        'signCount': json['sign_count'],
        'transports': json['transports'],
        'deviceType': CredentialDeviceTypeFromJSON(json['device_type']),
        'backUp': json['back_up'],
        'aaguid': json['aaguid'],
        'lastUseAt': json['last_use_at'] == null ? undefined : (new Date(json['last_use_at'])),
        'lastUseIp': json['last_use_ip'] == null ? undefined : json['last_use_ip'],
        'lastUseUa': json['last_use_ua'] == null ? undefined : json['last_use_ua'],
        'createdAt': (new Date(json['created_at'])),
    };
}
export function PasskeyToJSON(json) {
    return PasskeyToJSONTyped(json, false);
}
export function PasskeyToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'credential_id': value['credentialId'],
        'sign_count': value['signCount'],
        'transports': value['transports'],
        'device_type': CredentialDeviceTypeToJSON(value['deviceType']),
        'back_up': value['backUp'],
        'aaguid': value['aaguid'],
        'last_use_at': value['lastUseAt'] == null ? undefined : (value['lastUseAt'].toISOString()),
        'last_use_ip': value['lastUseIp'],
        'last_use_ua': value['lastUseUa'],
        'created_at': ((value['createdAt']).toISOString()),
    };
}
