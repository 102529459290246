/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoleFromJSON, RoleToJSON, } from './Role';
import { ProfileFromJSON, ProfileToJSON, } from './Profile';
/**
 * Check if a given object implements the Group interface.
 */
export function instanceOfGroup(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('mailingList' in value) || value['mailingList'] === undefined)
        return false;
    if (!('roles' in value) || value['roles'] === undefined)
        return false;
    if (!('members' in value) || value['members'] === undefined)
        return false;
    return true;
}
export function GroupFromJSON(json) {
    return GroupFromJSONTyped(json, false);
}
export function GroupFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'color': json['color'] == null ? undefined : json['color'],
        'id': json['id'],
        'mailingList': json['mailing_list'],
        'roles': (json['roles'].map(RoleFromJSON)),
        'members': (json['members'].map(ProfileFromJSON)),
    };
}
export function GroupToJSON(json) {
    return GroupToJSONTyped(json, false);
}
export function GroupToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'color': value['color'],
        'id': value['id'],
        'mailing_list': value['mailingList'],
        'roles': (value['roles'].map(RoleToJSON)),
        'members': (value['members'].map(ProfileToJSON)),
    };
}
