/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { LoginDataToJSON, PasskeyFromJSON, ResetPasswordToJSON, ResetPasswordRequestToJSON, TokenFromJSON, } from '../models/index';
/**
 *
 */
export class AuthenticationApi extends runtime.BaseAPI {
    /**
     * Delete Passkey
     */
    deletePasskeyApiV1WebauthnCredentialIdDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['credentialId'] == null) {
                throw new runtime.RequiredError('credentialId', 'Required parameter "credentialId" was null or undefined when calling deletePasskeyApiV1WebauthnCredentialIdDelete().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/webauthn/{credential_id}`.replace(`{${"credential_id"}}`, encodeURIComponent(String(requestParameters['credentialId']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Delete Passkey
     */
    deletePasskeyApiV1WebauthnCredentialIdDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.deletePasskeyApiV1WebauthnCredentialIdDeleteRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * List Passkeys
     */
    listPasskeysApiV1WebauthnGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/webauthn/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PasskeyFromJSON));
        });
    }
    /**
     * List Passkeys
     */
    listPasskeysApiV1WebauthnGet() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.listPasskeysApiV1WebauthnGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Logout
     */
    logoutApiV1AuthLogoutPostRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/v1/auth/logout`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Logout
     */
    logoutApiV1AuthLogoutPost(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.logoutApiV1AuthLogoutPostRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Prepare Login
     */
    prepareLoginApiV1AuthLoginGetRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/v1/auth/login`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Prepare Login
     */
    prepareLoginApiV1AuthLoginGet(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.prepareLoginApiV1AuthLoginGetRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Preregister Passkey
     */
    preregisterPasskeyApiV1WebauthnPreregisterGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/webauthn/preregister`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Preregister Passkey
     */
    preregisterPasskeyApiV1WebauthnPreregisterGet() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.preregisterPasskeyApiV1WebauthnPreregisterGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Process Login
     */
    processLoginApiV1AuthLoginPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['loginData'] == null) {
                throw new runtime.RequiredError('loginData', 'Required parameter "loginData" was null or undefined when calling processLoginApiV1AuthLoginPost().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/v1/auth/login`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: LoginDataToJSON(requestParameters['loginData']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
        });
    }
    /**
     * Process Login
     */
    processLoginApiV1AuthLoginPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.processLoginApiV1AuthLoginPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Register Passkey
     */
    registerPasskeyApiV1WebauthnRegisterPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['requestBody'] == null) {
                throw new runtime.RequiredError('requestBody', 'Required parameter "requestBody" was null or undefined when calling registerPasskeyApiV1WebauthnRegisterPost().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/webauthn/register`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters['requestBody'],
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Register Passkey
     */
    registerPasskeyApiV1WebauthnRegisterPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.registerPasskeyApiV1WebauthnRegisterPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Reset Password
     */
    resetPasswordApiV1AuthResetPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['token'] == null) {
                throw new runtime.RequiredError('token', 'Required parameter "token" was null or undefined when calling resetPasswordApiV1AuthResetPost().');
            }
            if (requestParameters['resetPassword'] == null) {
                throw new runtime.RequiredError('resetPassword', 'Required parameter "resetPassword" was null or undefined when calling resetPasswordApiV1AuthResetPost().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters['token'] != null) {
                headerParameters['token'] = String(requestParameters['token']);
            }
            const response = yield this.request({
                path: `/api/v1/auth/reset`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ResetPasswordToJSON(requestParameters['resetPassword']),
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Reset Password
     */
    resetPasswordApiV1AuthResetPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.resetPasswordApiV1AuthResetPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Reset Password Request
     */
    resetPasswordRequestApiV1AuthResetPasswordRequestPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['resetPasswordRequest'] == null) {
                throw new runtime.RequiredError('resetPasswordRequest', 'Required parameter "resetPasswordRequest" was null or undefined when calling resetPasswordRequestApiV1AuthResetPasswordRequestPost().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/v1/auth/reset_password_request`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ResetPasswordRequestToJSON(requestParameters['resetPasswordRequest']),
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Reset Password Request
     */
    resetPasswordRequestApiV1AuthResetPasswordRequestPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.resetPasswordRequestApiV1AuthResetPasswordRequestPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
