/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * A determination of the number of devices a credential can be used from
 *
 * Members:
 *     `SINGLE_DEVICE`: A credential that is bound to a single device
 *     `MULTI_DEVICE`: A credential that can be used from multiple devices (e.g. passkeys)
 *
 * https://w3c.github.io/webauthn/#sctn-credential-backup (L3 Draft)
 * @export
 */
export const CredentialDeviceType = {
    SingleDevice: 'single_device',
    MultiDevice: 'multi_device'
};
export function instanceOfCredentialDeviceType(value) {
    for (const key in CredentialDeviceType) {
        if (Object.prototype.hasOwnProperty.call(CredentialDeviceType, key)) {
            if (CredentialDeviceType[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function CredentialDeviceTypeFromJSON(json) {
    return CredentialDeviceTypeFromJSONTyped(json, false);
}
export function CredentialDeviceTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function CredentialDeviceTypeToJSON(value) {
    return value;
}
export function CredentialDeviceTypeToJSONTyped(value, ignoreDiscriminator) {
    return value;
}
